header {
  width: 100%;
  margin-left: auto;
  margin-right: auto;

  ul {
    display: flex;
    flex-flow: row;
    justify-content: flex-end;
    width: 900px;
    max-width: 90vw;
    height: 80px;
    text-align: center;
    margin: auto;

    a,
    .LoginButton {
      font-size: 16px;
      border: none;
      background: none;
      color: #5bbfbf;
      font-weight: 600;
      text-decoration: none;
      padding: 32px;
      @media (max-width: 700px), (max-device-width: 700px) {
        padding: 1rem;
        font-size: 0.9rem;
        margin-left: auto;
        margin-right: auto;
      }

      &:hover {
        text-decoration: underline;
      }
    }

    .LoginButton {
      margin-top: -2px; // fix not lining up with links.
      cursor: pointer;

      @media (max-width: 700px), (max-device-width: 700px) {
        display: none;
      }
    }
  }
}

.imgContainer {
  width: 100%;

  img {
    display: flex;
    margin: auto;
  }
}

.HeaderContainer {
  width: 900px;
  max-width: 90vw;
  margin: auto;
  margin-top: 60px;

  h1 {
    margin-left: 0px;
    padding: 10px;
  }
}
