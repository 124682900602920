@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;600&display=swap');

$colorWhite: #fff;
$colorOrange: #ee6b60;
$colorTeal: #60a3ee;
$colorDarkBlue: #003366;
$colorNotAsDarkBlue: #325b84;
$tableBackgorund: #002447;
$colorGreen: #23c123;
$colorRed: #e61414;

*,
all {
  font-family: Montserrat, sans-serif;
  box-sizing: border-box;
  color: $colorWhite;
  font-weight: 500;
}

body {
  font-family: Montserrat, sans-serif;
  min-height: 100vh;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  background: $colorDarkBlue;
  background: -moz-linear-gradient(
    top,
    $colorDarkBlue 0%,
    66%,
    $colorNotAsDarkBlue 100%
  );
  background: -webkit-linear-gradient(
    top,
    $colorDarkBlue 0%,
    66%,
    $colorNotAsDarkBlue 100%
  );
  background: $colorDarkBlue
    linear-gradient(to bottom, $colorDarkBlue 0%, 66%, $colorNotAsDarkBlue 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr=$colorDarkBlue, endColorstr=$colorNotAsDarkBlue, GradientType=0 );
}

h1 {
  color: $colorOrange;
  font-size: 2rem;
  font-weight: 600;
  margin: 10px 30px;
}

a {
  color: $colorOrange;
}

// fix Mantine modals going off screen.
.mantine-Modal-inner {
  left: 0;
}
