@import '../../styles/main.scss';

.Input {
  width: fit-content;
  display: flex;
  align-items: center;
  height: 39px;
  background-color: rgba(#fff, 0.1);
  border: none;
  color: $colorWhite;
  font-size: 16px;
  width: 400px;
  max-width: 100%;

  > input {
    font-size: 16px;
    padding: 10px;
    border: none;
    background-image: none;
    background-color: transparent;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    width: 100%;

    &::placeholder {
      color: #aaa;
      opacity: 1;
    }
  }

  > svg {
    font-size: 22px;
    margin-left: 10px;
  }
}
