@import '../../styles/main.scss';

.Button {
  cursor: pointer;
  display: inline-block;
  font-weight: 400;
  color: $colorWhite;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  border: 3px solid $colorWhite;
  font-weight: 600;

  &:hover {
    color: initial;
    background: $colorWhite;
  }
}

.Button:disabled {
  opacity: 0.5;

  &:hover {
    cursor: not-allowed;
    color: $colorWhite;
    background-color: transparent;
  }
}
