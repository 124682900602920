@import '../../styles/main.scss';

.Box {
  background-color: rgba(0, 0, 0, 0.2);
  width: 900px;
  max-width: 90vw;
  padding: 30px;
  margin: 20px 0;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  cursor: pointer;
  border-radius: 0.25rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > h2 {
    font-size: 1.7rem;
    color: $colorOrange;
    min-width: 200px;
    margin-bottom: 20px;
  }

  &-dropzone {
    padding: 30px 0 0 0;
  }
}

.ProButton {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.Dropzone {
  min-height: 150px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  > p {
    color: $colorTeal;
    font-size: 1.1rem;
    margin: 40px 30px 30px;
  }
}

.Dropzone-file {
  display: flex;
  align-items: center;
  padding: 20px;
  border-bottom: 1.5px solid rgba(255, 255, 255, 0.9);
  width: 90%;
  max-width: 500px;

  > svg {
    font-size: 2.5rem;
    fill: $colorTeal;
    margin-right: 5px;
  }

  .File-metadata {
    text-align: left;

    > h1 {
      text-align: left;
      line-height: 1.3;
      font-size: 1.2rem;
      font-weight: 500;
      color: #fff;
      margin: 0;
    }

    > p {
      font-size: 0.8rem;
      line-height: 1.1;
    }
  }
}

.Dropzone-loading,
.Dropzone-check,
.Dropzone-cross {
  margin-left: auto;
  font-size: 1.8rem !important;
}

.Dropzone-loading {
  animation: spinner 0.6s linear infinite;
  fill: $colorTeal !important;
}

.Dropzone-check {
  fill: $colorGreen !important;
}

.Dropzone-cross {
  fill: $colorRed !important;
}

.Dropzone-error {
  color: $colorRed !important;
}

.SubmitControls {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;

  > * {
    margin: 0.7em;
  }
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}
