.ControlContent {
  width: 900px;
  height: 100px;
  max-width: 90vw;
  margin: auto;
  line-height: 1.4;
  padding: 7px;

  > * {
    margin: 0.5rem 0;
  }
}

.Songs {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ControlTable {
  table {
    width: 100%;
    height: 150px;
  }
  th {
    color: #ee6b60;
  }
  tr {
    text-align: center;
  }
  td {
    text-align: center;
  }

  button {
    background-color: black;
    color: white;
    padding: 5px 20px;
  }
}
